<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/PageHeader";
import appConfig from "../../../app.config";

import axios from 'axios';
import ProfileItem from "./ProfileItem.vue";

export default {
  page: {
    title: "Profiles",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      data: {
        list: []
      }
    };
  },
  components: {
    Layout,
    PageHeader,
    ProfileItem
  },
  created: function () {
    axios.get('/api/profiles/list')
      .then((response) => {
        this.data = response.data;
      })
      .catch(function () {
      });
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('t-profiles-title')" :description="$t('t-profiles-description')" />
    
    <div class="row">
      <div class="col-lg-12">
        <div>
          <div class="team-list grid-view-filter row">
            <div class="col" v-for="p in data.list" v-bind:key="p">
              <ProfileItem v-bind:data="p"></ProfileItem>
            </div>
          </div>
        </div>
      </div>

    </div>

  </Layout>
</template>