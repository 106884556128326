<script>
export default {
  data() {
    return {
    };
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
          id: null,
          login: '',
          displayName: '',
          urlAvatar: '',
          urlCover: '',
          isVerified: false,
          stat: {},
          flags: {
            isNew: false
          }
        }
      }
    },
    showUrl: Boolean,
  },
  components: {
  },
  computed: {
    getProfileUrl: function() {
      try {
        return new URL(this.data.url).hostname;
      }
      catch {
        return null;
      }
    }
  }
};
</script>

<template>
  <div class="card team-box ribbon-box ribbon-fill">
    <div class="team-cover" v-if="data.urlCover">
      <img v-bind:src="data.urlCover" alt="" class="img-fluid" />
    </div>
    <div class="card-body p-4">
      <div class="ribbon ribbon-danger fs-small" v-if="data.flags.isNew"><small>New</small></div>
      <div class="row align-items-center team-row">
        <div class="col team-settings">
          <div class="row">
            <div class="col">
            </div>
            <div class="col text-end dropdown">
              <a href="javascript:void(0);" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="ri-more-fill fs-17"></i>
              </a>
              <ul class="dropdown-menu dropdown-menu-end">
                <li>
                  <router-link :to="{ name: 'profile', params: { login: data.login } }" class="dropdown-item">
                    <i class="ri-eye-line me-2 align-middle"></i> View
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col">

          <div class="team-profile-img">
            <div class="avatar-xl img-thumbnail rounded-circle flex-shrink-0">
              <router-link :to="{ name: 'profile', params: { login: data.login } }">
                <div class="avatar-title rounded-circle text-center" v-if="!data.urlAvatar">{{ data.login }}</div>
                <img v-bind:src="data.urlAvatar" alt="" class="img-fluid d-block rounded-circle w-100" />
              </router-link>
            </div>
            <div class="team-content">
              <router-link :to="{ name: 'profile', params: { login: data.login } }">
                <h5 class="fs-16 mb-1">
                  {{ data.login }}
                  <i v-if="data.isVerified" class="ri-check-fill text-primary"></i>
                </h5>
              </router-link>
              <p class="text-muted mb-0">{{ data.displayName }}</p>
              <p class="text-primary mb-0" v-if="data.url && showUrl">
                <i class="ri-link-m"></i> <a v-bind:href="data.url" target="_blank" class="text-primary">{{ getProfileUrl }}</a>
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col">
          <div class="row text-muted text-center">
            <div class="col-4 border-end border-end-dashed">
              <h5 class="mb-1">{{ data.stat.podcasts }}</h5>
              <p class="text-muted mb-0">Podcasts</p>
            </div>
            <div class="col-4 border-end border-end-dashed">
              <h5 class="mb-1">{{ data.stat.episodes }}</h5>
              <p class="text-muted mb-0">Episodes</p>
            </div>
            <div class="col-4">
              <h5 class="mb-1">{{ data.stat.favourites }}</h5>
              <p class="text-muted mb-0">Favs</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>